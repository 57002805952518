<template>
  <div class="customer-form-section">
    <Load v-if="isDecryptedLoading || isMemberLoading" />

    <section class="content-section">
      <v-container>
        <div class="text-center mb-6">
          <div class="headline-l mb-4">
            ลงทะเบียนรับสิทธิการจองก่อนใคร
          </div>
          <div>
            เพื่อความรวดเร็วในการสั่งจอง
            <br />
            ลูกค้าสามารถกรอกข้อมูลที่อยู่จัดส่งและออกใบกำกับภาษีได้ล่วงหน้า
            ได้ที่แบบฟอร์มด้านล่างนี้
          </div>
        </div>

        <div class="d-flex justify-center flex-wrap mb-8">
          <div class="headline-s w-full text-center mb-4">
            คุณเคยซื้อสินค้าที่ Studio 7 ภายใน 2 ปี หรือไม่
          </div>
          <v-radio-group
            v-model="isHavePurchased"
            :disabled="isMember"
            row
            hide-details
          >
            <v-radio
              v-for="(item, index) in havePurchasedOptions"
              :key="index"
              :label="item.text"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </div>

        <div
          v-if="isHavePurchased || (!isHavePurchased && isError)"
          class="seven-club-member-container"
        >
          <SevenClubMember
            :isCheckout="true"
            :isPreRegister="true"
            @verified="handleVerifySevenClubMember"
            @error="handleErrorSevenClubMember"
            @reset="reset"
          />
        </div>
        <div v-if="isMember" class="status-scm-success mt-6">
          ท่านเป็นสมาชิก 7 Club Member อยู่แล้ว กรุณาตรวจสอบ
          และกดยืนยันข้อมูลส่วนตัวของท่าน เพื่อความรวดเร็วในการจอง
        </div>
        <div v-if="isError" class="status-scm-warning mt-6">
          ท่านยังไม่เป็นสมาชิก 7 Club Member
          กรุณากรอกข้อมูลส่วนตัวของท่าน เพื่อรับสิทธิจองก่อนใคร
        </div>
      </v-container>
    </section>

    <section class="content-section">
      <v-container v-if="isBnn">
        <v-row
          class="w-full"
          v-bind="{ justify: 'center', 'no-gutters': true }"
        >
          <h2 class="py-8">
            ที่อยู่ใบเสร็จและจัดส่ง
          </h2>
        </v-row>
        <div class="note-form-address text-red">
          <div>
            โปรดกรอกอีเมลและเบอร์โทรที่ติดต่อได้
          </div>
          <div>
            ขอสงวนสิทธิ์ไม่รับผิดชอบหากกรอกข้อมูลผิดทุกกรณี
          </div>
          <template v-if="campaign.data.order_limit > 0">
            <div>
              สงวนสิทธิ์ในการสั่งซื้อ 1 ท่าน /
              {{ campaign.data.order_limit }} เครื่อง / 1 เบอร์โทร / 1
              อีเมล เท่านั้น
            </div>
            <div>
              บริษัทจะขอยกเลิกออเดอร์หากตรวจพบว่าท่านได้ทำการสั่งจองเกินสิทธิ์แล้ว
            </div>
          </template>
        </div>

        <v-row>
          <v-col cols="12" class="text-dark-gray">
            <form class="form-customer-layout">
              <v-row>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    อีเมล <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    v-model="form.email"
                    background-color="white"
                    data-pptr="email"
                    class="input-customer text-sm mt-2"
                    type="email"
                    ref="email"
                    placeholder="อีเมล"
                    :error="$v.form.email.$error"
                    :color="colorBnn['color-bnn']"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    เบอร์โทรศัพท์ <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    v-model="form.phone"
                    ref="phone"
                    background-color="white"
                    data-pptr="phone"
                    class="input-customer text-sm mt-2"
                    maxlength="10"
                    type="tel"
                    placeholder="เบอร์โทรศัพท์"
                    :error="$v.form.phone.$error"
                    :color="colorBnn['color-bnn']"
                    @keypress="isNumber($event)"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    ชื่อ <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    v-model="form.first_name"
                    background-color="white"
                    data-pptr="first_name"
                    class="input-customer text-sm mt-2"
                    type="text"
                    ref="first_name"
                    placeholder="ชื่อ"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.first_name.$error"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    นามสกุล <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    v-model="form.last_name"
                    background-color="white"
                    data-pptr="last_name"
                    class="input-customer text-sm mt-2"
                    type="text"
                    ref="last_name"
                    placeholder="นามสกุล"
                    :error="$v.form.last_name.$error"
                    :color="colorBnn['color-bnn']"
                  />
                </v-col>

                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    เลขบัตรประชนชน/Passport
                    <span class="text-red">*</span>
                  </label>
                  <v-row
                    v-bind="{
                      'no-gutters': true,
                    }"
                  >
                    <v-col
                      cols="6"
                      sm="5"
                      class="input-compact-container"
                    >
                      <v-select
                        v-model="form.customerIdentityType"
                        :items="[
                          {
                            text: 'เลขบัตรประชาชน',
                            value: 'id_card',
                          },
                          { text: 'Passport', value: 'passport' },
                        ]"
                        :color="colorBnn['color-bnn']"
                        :width="150"
                        :max-width="150"
                        class="input-compact"
                        outlined
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="7"
                      class="input-compact-container"
                    >
                      <v-text-field
                        v-if="form.customerIdentityType === 'id_card'"
                        outlined
                        :color="colorBnn['color-bnn']"
                        v-model="form.customer_identity"
                        background-color="white"
                        data-pptr="customer_identity"
                        class="input-customer text-sm input-compact"
                        :error="$v.form.customer_identity.$error"
                        :error-messages="
                          handleErrorCustomerIdentity(
                            'customer_identity',
                          )
                        "
                        :hide-details="
                          !$v.form.customer_identity.$error
                        "
                        ref="customer_identity"
                        maxlength="17"
                        @input="
                          autoFormatIdCard(
                            $event,
                            'customer_identity',
                          ),
                            $v.form.customer_identity.$reset()
                        "
                        inputmode="numeric"
                        oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                        placeholder="เลขบัตรประชาชน"
                      />

                      <v-text-field
                        v-else
                        outlined
                        :color="colorBnn['color-bnn']"
                        v-model="form.customer_identity"
                        background-color="white"
                        data-pptr="customer_identity"
                        class="input-customer text-sm input-compact"
                        :error="$v.form.customer_identity.$error"
                        :error-messages="
                          handleErrorCustomerIdentity(
                            'customer_identity',
                          )
                        "
                        :hide-details="
                          !$v.form.customer_identity.$error
                        "
                        ref="customer_identity"
                        maxlength="12"
                        oninput="this.value=this.value.slice(0,12).replace(/[^\w]+/g, '')"
                        placeholder="Passport"
                      />
                    </v-col>
                  </v-row>
                  <!-- <v-text-field
                    v-model="form.customer_identity"
                    placeholder="x-xxxx-xxxxx-xx-x"
                    background-color="white"
                    data-pptr="customer_identity"
                    class="input-customer text-sm mt-2"
                    type="text"
                    ref="customer_identity"
                    oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                    inputmode="numeric"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.customer_identity.$error"
                    :error-messages="
                      handleErrorCustomerIdentity('customer_identity')
                    "
                    :hide-details="!$v.form.customer_identity.$error"
                    :minlength="13"
                    :maxlength="17"
                    @input="
                      autoFormatIdCard($event, 'customer_identity'),
                        $v.form.customer_identity.$reset()
                    "
                  /> -->
                </v-col>

                <div class="my-4"></div>
                <v-col cols="12" md="12" class="pb-0">
                  <label class="mb-18">
                    บ้าน/อาคาร เลขที่
                    <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    v-model="form.address"
                    background-color="white"
                    data-pptr="address"
                    class="input-customer text-sm"
                    type="text"
                    ref="address"
                    placeholder="บ้านเลขที่,ห้อง,หมู่บ้าน,ชื่ออาคาร,ตึก,คอนโด"
                    :error="$v.form.address.$error"
                    :color="colorBnn['color-bnn']"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    รหัสไปรษณีย์ <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    v-model="form.postcode"
                    background-color="white"
                    data-pptr="postcode"
                    class="input-customer text-sm"
                    type="text"
                    ref="postcode"
                    maxlength="5"
                    placeholder="รหัสไปรษณีย์"
                    inputmode="numeric"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.postcode.$error"
                    @keypress="isNumber($event)"
                    @blur="onBlurAutoComplete"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    จังหวัด <span class="text-red">*</span>
                  </label>
                  <v-autocomplete
                    v-model="form.province"
                    background-color="white"
                    class="text-sm"
                    data-pptr="province"
                    item-text="province"
                    item-value="province"
                    placeholder="จังหวัด"
                    ref="province"
                    :class="{
                      'has-error': $v.form.province.$error,
                    }"
                    :items="provinceData"
                    :error="$v.form.province.$error"
                    :color="colorBnn['color-bnn']"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    เขต/อำเภอ <span class="text-red">*</span>
                  </label>
                  <v-autocomplete
                    v-model="form.district"
                    ref="district"
                    background-color="white"
                    class="text-sm"
                    data-pptr="amphoe"
                    item-text="amphoe"
                    item-value="amphoe"
                    placeholder="เขต/อำเภอ"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.district.$error"
                    :class="{
                      'has-error': $v.form.district.$error,
                    }"
                    :items="districtData"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    แขวง/ตำบล <span class="text-red">*</span>
                  </label>
                  <v-autocomplete
                    v-model="form.sub_district"
                    ref="sub_district"
                    background-color="white"
                    class="text-sm"
                    data-pptr="sub_district"
                    item-text="district"
                    item-value="district"
                    placeholder="แขวง/ตำบล"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.sub_district.$error"
                    :class="{
                      'has-error': $v.form.sub_district.$error,
                    }"
                    :items="subDistrictData"
                  ></v-autocomplete>
                </v-col>

                <template v-if="samsungHandraiser">
                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      มือถือที่ใช้อยู่ ณ ปัจจุบัน
                    </label>
                    <v-row>
                      <v-col cols="12" md="12" class="py-0">
                        <v-autocomplete
                          v-model="form.additional_description.brand"
                          ref="taxSubDistrict"
                          clearable
                          background-color="white"
                          class="text-sm"
                          data-pptr="brand"
                          item-text="brand"
                          item-value="brand"
                          placeholder="ยี่ห้อมือถือที่ใช้งานอยู่"
                          :color="colorBnn['color-bnn']"
                          :items="additionalDescriptionBrand"
                          @click:clear="
                            form.additional_description.model = ''
                          "
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="12" class="py-0">
                        <v-autocomplete
                          v-model="form.additional_description.model"
                          ref="taxSubDistrict"
                          clearable
                          background-color="white"
                          class="text-sm"
                          data-pptr="model"
                          item-text="model"
                          item-value="model"
                          placeholder="รุ่นมือถือที่ใช้งานอยู่"
                          :color="colorBnn['color-bnn']"
                          :items="additionalDescriptionModel"
                          :error="
                            $v.form.additional_description.model
                              .$error
                          "
                          :error-messages="
                            $v.form.additional_description.model
                              .$error
                              ? 'กรุณาเลือกรุ่นมือถือที่ใช้งานอยู่'
                              : ''
                          "
                          :class="{
                            'has-error':
                              $v.form.additional_description.model
                                .$error,
                          }"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      ลูกค้าสนใจ promotion แลกซื้อหรือไม่ /
                      หากสนใจเป็นสินค้าใด?
                    </label>
                    <v-autocomplete
                      v-model="
                        form.additional_description.interest_item
                      "
                      ref="interest_item"
                      clearable
                      background-color="white"
                      class="text-sm"
                      data-pptr="interest_item"
                      item-text="interest_item"
                      item-value="interest_item"
                      placeholder="หากสนใจ สนใจสินค้าใด"
                      :color="colorBnn['color-bnn']"
                      :items="additionalDescriptionInterest"
                    ></v-autocomplete>
                  </v-col>
                </template>
              </v-row>

              <v-row>
                <v-col v-if="customFieldOnCart" cols="12" md="12">
                  <div class="text-xl font-semibold w-fit-content">
                    <v-checkbox
                      :input-value="form.need_true_phone"
                      :true-value="true"
                      :color="colorBnn['color-bnn']"
                      label="ต้องการเปิดเบอร์ True รับส่วนลดสูงสุด 8,800 บาท"
                      background-color="#f5f5f7"
                      @click="checkNeedTruePhone"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-xl font-semibold w-fit-content">
                    <v-checkbox
                      v-model="form.checkReceipt"
                      :true-value="true"
                      :color="colorBnn['color-bnn']"
                      label="ต้องการรับใบกำกับภาษี / แก้ไขใบเสร็จ"
                      background-color="#f5f5f7"
                    ></v-checkbox>
                  </div>
                  <div class="warning-tax-invoice">
                    <div class="warning-tax-invoice-title">
                      <span class="text-alert">สำคัญ!</span>
                      ใบกำกับภาษีของท่านจะถูกจัดส่งทางอีเมล
                    </div>
                    <div>
                      กรุณาตรวจสอบชื่อและที่อยู่ในการออกใบกำกับภาษีให้ถูกต้องทุกครั้ง
                      ทางบริษัทฯขอสงวนสิทธิ์ในการแก้ไขใบกำกับภาษี
                    </div>
                  </div>
                </v-col>

                <template v-if="form.checkReceipt">
                  <v-col cols="12" md="3">
                    <v-radio-group
                      v-model="form.taxInvoiceType"
                      inline
                      ref="taxInvoiceType"
                      data-pptr="taxInvoiceType"
                      :error="$v.form.taxInvoiceType.$error"
                    >
                      <v-radio
                        value="PERSONAL"
                        :label="`ใบกำกับภาษีส่วนบุคคล`"
                        :color="colorBnn['color-bnn']"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-radio-group
                      v-model="form.taxInvoiceType"
                      ref="taxInvoiceType"
                      inline
                      data-pptr="taxInvoiceType"
                      :error="$v.form.taxInvoiceType.$error"
                    >
                      <v-radio
                        value="CORPORATION"
                        :label="`ใบกำกับภาษีนิติบุคคล`"
                        :color="colorBnn['color-bnn']"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" class="pb-0">
                    <v-row>
                      <!-- Personal -->
                      <template v-if="checkTaxPersonal">
                        <v-col cols="12" md="12">
                          <label class="mb-18">
                            เลขประจำตัวผู้เสียภาษี
                            <span class="text-red">*</span>
                          </label>
                          <v-row
                            v-bind="{
                              'no-gutters': true,
                            }"
                          >
                            <v-col
                              cols="6"
                              sm="5"
                              class="input-compact-container"
                            >
                              <v-select
                                v-model="form.checkTaxPersonalType"
                                :items="[
                                  {
                                    text: 'เลขประจำตัวผู้เสียภาษี',
                                    value: 'id_card',
                                  },
                                  {
                                    text: 'Passport',
                                    value: 'passport',
                                  },
                                ]"
                                :color="colorBnn['color-bnn']"
                                :width="150"
                                :max-width="150"
                                class="input-compact"
                                outlined
                                hide-details
                              ></v-select>
                            </v-col>
                            <v-col
                              cols="6"
                              sm="7"
                              class="input-compact-container"
                            >
                              <v-text-field
                                v-if="
                                  checkTaxPersonalType === 'id_card'
                                "
                                outlined
                                :color="colorBnn['color-bnn']"
                                v-model="form.tax_id"
                                background-color="white"
                                data-pptr="tax_id"
                                class="input-customer text-sm input-compact"
                                :error="$v.form.tax_id.$error"
                                :error-messages="
                                  handleErrorCustomerIdentity(
                                    'tax_id',
                                  )
                                "
                                :hide-details="!$v.form.tax_id.$error"
                                ref="tax_id"
                                maxlength="17"
                                @input="
                                  autoFormatIdCard($event, 'tax_id'),
                                    $v.form.tax_id.$reset()
                                "
                                inputmode="numeric"
                                oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                                placeholder="เลขประจำตัวผู้เสียภาษี"
                              />

                              <v-text-field
                                v-if="
                                  checkTaxPersonalType === 'passport'
                                "
                                outlined
                                :color="colorBnn['color-bnn']"
                                v-model="form.tax_id"
                                background-color="white"
                                data-pptr="tax_id"
                                class="input-customer text-sm input-compact"
                                :error="$v.form.tax_id.$error"
                                :error-messages="
                                  handleErrorCustomerIdentity(
                                    'tax_id',
                                  )
                                "
                                :hide-details="!$v.form.tax_id.$error"
                                ref="tax_id"
                                maxlength="12"
                                oninput="this.value=this.value.slice(0,12).replace(/[^\w]+/g, '')"
                                placeholder="Passport"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            ชื่อ
                            <span class="text-red">*</span>
                          </label>
                          <v-text-field
                            v-model="form.taxFirstName"
                            background-color="white"
                            data-pptr="taxFirstName"
                            class="input-customer text-sm"
                            ref="taxFirstName"
                            maxlength="100"
                            type="text"
                            placeholder="ชื่อ"
                            :color="colorBnn['color-bnn']"
                            :error="$v.form.taxFirstName.$error"
                          />
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            นามสกุล
                            <span class="text-red">*</span>
                          </label>
                          <v-text-field
                            v-model="form.taxLastName"
                            background-color="white"
                            data-pptr="taxLastName"
                            class="input-customer text-sm"
                            maxlength="100"
                            ref="taxLastName"
                            type="text"
                            placeholder="นามสกุล"
                            :color="colorBnn['color-bnn']"
                            :error="$v.form.taxLastName.$error"
                          />
                        </v-col>
                      </template>
                      <!-- Company -->
                      <template v-if="checkTaxJuristic">
                        <v-col cols="12" md="12">
                          <label class="mb-18">
                            เลขประจำตัวผู้เสียภาษี
                            <span class="text-red">*</span>
                          </label>
                          <v-text-field
                            :color="colorBnn['color-bnn']"
                            v-model="form.tax_id"
                            background-color="white"
                            data-pptr="tax_id"
                            class="input-customer text-sm input-compact"
                            :error="$v.form.tax_id.$error"
                            :error-messages="
                              handleErrorCustomerIdentity('tax_id')
                            "
                            :hide-details="!$v.form.tax_id.$error"
                            ref="tax_id"
                            maxlength="17"
                            oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                            inputmode="numeric"
                            placeholder="เลขประจำตัวผู้เสียภาษี"
                          />
                        </v-col>

                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            ชื่อบริษัท
                            <span class="text-red">*</span>
                          </label>
                          <v-text-field
                            v-model="form.taxCompanyName"
                            background-color="white"
                            data-pptr="taxCompanyName"
                            class="input-customer text-sm"
                            maxlength="100"
                            type="text"
                            ref="taxCompanyName"
                            placeholder="ชื่อบริษัท"
                            :color="colorBnn['color-bnn']"
                            :error="$v.form.taxCompanyName.$error"
                          />
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            รหัสสาขา
                            <span class="text-red">*</span>
                          </label>
                          <v-text-field
                            v-model="form.taxCompanyCode"
                            background-color="white"
                            data-pptr="taxCompanyCode"
                            class="input-customer text-sm"
                            maxlength="100"
                            type="text"
                            ref="taxCompanyCode"
                            placeholder="รหัสสาขา"
                            :color="colorBnn['color-bnn']"
                            :error="$v.form.taxCompanyCode.$error"
                          />
                        </v-col>
                      </template>

                      <v-col cols="12">
                        <div class="text-xl w-fit-content">
                          <v-checkbox
                            v-model="form.sameAsDeliveryAddress"
                            class="mt-0"
                            background-color="#f5f5f7"
                            :label="`ที่อยู่เดียวกับที่อยู่จัดส่ง`"
                            :true-value="true"
                            :color="colorBnn['color-bnn']"
                          ></v-checkbox>
                        </div>
                      </v-col>

                      <template v-if="!form.sameAsDeliveryAddress">
                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            บ้าน/อาคาร/เลขที่
                            <span class="text-red">*</span>
                          </label>
                          <v-text-field
                            v-model="form.taxAddress1"
                            background-color="white"
                            data-pptr="taxAddress1"
                            class="input-customer text-sm"
                            maxlength="100"
                            type="text"
                            ref="taxAddress1"
                            placeholder="บ้าน/อาคาร/เลขที่"
                            :color="colorBnn['color-bnn']"
                            :error="$v.form.taxAddress1.$error"
                          />
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            หมู่บ้าน/อาคาร/สถานที่
                          </label>
                          <v-text-field
                            v-model="form.taxAddress2"
                            background-color="white"
                            data-pptr="taxAddress2"
                            class="input-customer text-sm"
                            maxlength="100"
                            type="text"
                            ref="taxAddress2"
                            placeholder="หมู่บ้าน/อาคาร/สถานที่"
                            :color="colorBnn['color-bnn']"
                          />
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0">
                          <label class="mb-18">
                            ที่อยู่/ถนน/ซอย
                            <span class="text-red">*</span>
                          </label>
                          <v-text-field
                            v-model="form.taxAddress3"
                            background-color="white"
                            data-pptr="taxAddress3"
                            class="input-customer text-sm"
                            maxlength="100"
                            type="text"
                            ref="taxAddress3"
                            placeholder="ที่อยู่/ถนน/ซอย"
                            :color="colorBnn['color-bnn']"
                            :error="$v.form.taxAddress3.$error"
                          />
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            จังหวัด
                            <span class="text-red">*</span>
                          </label>
                          <v-autocomplete
                            v-model="form.taxProvince"
                            background-color="white"
                            data-pptr="taxProvince"
                            item-text="province"
                            item-value="province"
                            placeholder="จังหวัด"
                            ref="taxProvince"
                            :items="provinceData"
                            :error="$v.form.taxProvince.$error"
                            :color="colorBnn['color-bnn']"
                            :class="[
                              'text-sm input-transparent',
                              {
                                'has-error':
                                  $v.form.taxProvince.$error,
                              },
                            ]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            เขต/อำเภอ
                            <span class="text-red">*</span>
                          </label>
                          <v-autocomplete
                            v-model="form.taxDistrict"
                            background-color="white"
                            data-pptr="taxDistrict"
                            item-text="amphoe"
                            item-value="amphoe"
                            placeholder="เขต/อำเภอ"
                            ref="taxDistrict"
                            :color="colorBnn['color-bnn']"
                            :class="[
                              'text-sm',
                              {
                                'has-error':
                                  $v.form.taxDistrict.$error,
                              },
                            ]"
                            :error="$v.form.taxDistrict.$error"
                            :items="taxDistrictData"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            แขวง/ตำบล
                            <span class="text-red">*</span>
                          </label>
                          <v-autocomplete
                            v-model="form.taxSubDistrict"
                            background-color="white"
                            data-pptr="taxSubDistrict"
                            item-text="district"
                            item-value="district"
                            placeholder="แขวง/ตำบล"
                            ref="taxSubDistrict"
                            :color="colorBnn['color-bnn']"
                            :class="[
                              'text-sm',
                              {
                                'has-error':
                                  $v.form.taxSubDistrict.$error,
                              },
                            ]"
                            :error="$v.form.taxSubDistrict.$error"
                            :items="taxSubDistrictData"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            รหัสไปรษณีย์
                            <span class="text-red">*</span>
                          </label>
                          <v-text-field
                            v-model="form.taxPostcode"
                            background-color="white"
                            data-pptr="taxPostcode"
                            class="input-customer text-sm"
                            type="text"
                            ref="taxPostcode"
                            maxlength="5"
                            placeholder="รหัสไปรษณีย์"
                            inputmode="numeric"
                            :color="colorBnn['color-bnn']"
                            :error="$v.form.taxPostcode.$error"
                            @keypress="isNumber($event)"
                          />
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0">
                          <label class="mb-18">
                            เบอร์โทรศัพท์
                            <span class="text-red">*</span>
                          </label>
                          <v-text-field
                            v-model="form.taxPhoneNumber"
                            background-color="white"
                            data-pptr="taxPhoneNumber"
                            class="input-customer text-sm"
                            maxlength="10"
                            type="tel"
                            ref="taxPhoneNumber"
                            placeholder="เบอร์โทรศัพท์"
                            :color="colorBnn['color-bnn']"
                            :error="$v.form.taxPhoneNumber.$error"
                            @keypress="isNumber($event)"
                          />
                        </v-col>
                      </template>
                    </v-row>
                  </v-col>
                </template>
              </v-row>
            </form>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-else>
        <div v-if="isShowForm" class="customer-container">
          <div v-if="!isHavePurchased" class="headline-l">
            กรอกข้อมูลรายละเอียดการจอง
          </div>

          <div class="headline-m">
            ข้อมูลส่วนตัว
            <!-- isHavePurchased: {{ isHavePurchased }} |
          isShowForm: {{ isShowForm }} | isMember: {{ isMember }} ||
          isError:
          {{ isError }} -->
          </div>
          <div class="customer-form-container">
            <div>
              <v-text-field
                v-model="form.email"
                background-color="white"
                data-pptr="email"
                class="input-customer text-sm mt-2"
                type="email"
                ref="email"
                placeholder="อีเมล"
                outlined
                hide-details
                label="อีเมล"
                :error="$v.form.email.$error"
                :color="colorBnn['color-bnn']"
              />
            </div>
            <div>
              <v-text-field
                v-model="form.phone"
                outlined
                background-color="white"
                data-pptr="phone"
                class="input-customer text-sm mt-2"
                maxlength="10"
                type="tel"
                ref="phone"
                hide-details
                label="เบอร์โทรศัพท์"
                :error="$v.form.phone.$error"
                :color="colorBnn['color-bnn']"
                @keypress="isNumber($event)"
              />
            </div>
            <div>
              <v-text-field
                v-model="form.first_name"
                background-color="white"
                data-pptr="first_name"
                class="input-customer text-sm mt-2"
                type="text"
                ref="first_name"
                label="ชื่อ"
                placeholder="ชื่อ"
                outlined
                hide-details
                :color="colorBnn['color-bnn']"
                :error="$v.form.first_name.$error"
              />
            </div>
            <div>
              <v-text-field
                v-model="form.last_name"
                background-color="white"
                data-pptr="last_name"
                class="input-customer text-sm mt-2"
                type="text"
                ref="last_name"
                placeholder="นามสกุล"
                outlined
                hide-details
                label="นามสกุล"
                :error="$v.form.last_name.$error"
                :color="colorBnn['color-bnn']"
              />
            </div>
            <div class="span-2">
              <v-row
                v-bind="{
                  'no-gutters': true,
                }"
              >
                <v-col
                  cols="6"
                  sm="5"
                  class="input-compact-container"
                >
                  <v-select
                    v-model="form.customerIdentityType"
                    :items="[
                      {
                        text: 'เลขบัตรประชาชน',
                        value: 'id_card',
                      },
                      { text: 'Passport', value: 'passport' },
                    ]"
                    :color="colorBnn['color-bnn']"
                    :width="150"
                    :max-width="150"
                    class="input-compact"
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
                <v-col
                  cols="6"
                  sm="7"
                  class="input-compact-container"
                >
                  <v-text-field
                    v-if="form.customerIdentityType === 'id_card'"
                    outlined
                    :color="colorBnn['color-bnn']"
                    v-model="form.customer_identity"
                    background-color="white"
                    data-pptr="customer_identity"
                    class="input-customer text-sm input-compact"
                    :error="$v.form.customer_identity.$error"
                    :error-messages="
                      handleErrorCustomerIdentity('customer_identity')
                    "
                    :hide-details="!$v.form.customer_identity.$error"
                    ref="customer_identity"
                    maxlength="17"
                    @input="
                      autoFormatIdCard($event, 'customer_identity'),
                        $v.form.customer_identity.$reset()
                    "
                    inputmode="numeric"
                    oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                    placeholder="เลขบัตรประชาชน"
                  />

                  <v-text-field
                    v-else
                    outlined
                    :color="colorBnn['color-bnn']"
                    v-model="form.customer_identity"
                    background-color="white"
                    data-pptr="customer_identity"
                    class="input-customer text-sm input-compact"
                    :error="$v.form.customer_identity.$error"
                    :error-messages="
                      handleErrorCustomerIdentity('customer_identity')
                    "
                    :hide-details="!$v.form.customer_identity.$error"
                    ref="customer_identity"
                    maxlength="12"
                    oninput="this.value=this.value.slice(0,12).replace(/[^\w]+/g, '')"
                    placeholder="Passport"
                  />
                </v-col>
              </v-row>
            </div>
          </div>

          <div class="headline-m">
            ที่อยู่ในการจัดส่ง/ออกใบเสร็จ
          </div>

          <div class="customer-form-container">
            <div class="span-2">
              <v-text-field
                v-model="form.address"
                outlined
                background-color="white"
                data-pptr="address"
                class="input-customer text-sm"
                type="text"
                ref="address"
                hide-details
                label="บ้านเลขที่,ห้อง,หมู่บ้าน,ชื่ออาคาร,ตึก,คอนโด"
                :error="$v.form.address.$error"
                :color="colorBnn['color-bnn']"
              />
            </div>

            <div>
              <v-text-field
                v-model="form.postcode"
                outlined
                hide-details
                label="รหัสไปรษณีย์"
                background-color="white"
                data-pptr="postcode"
                class="input-customer text-sm"
                type="text"
                ref="postcode"
                maxlength="5"
                placeholder="รหัสไปรษณีย์"
                inputmode="numeric"
                :color="colorBnn['color-bnn']"
                :error="$v.form.postcode.$error"
                @keypress="isNumber($event)"
                @blur="onBlurAutoComplete"
              />
            </div>

            <div>
              <v-autocomplete
                v-model="form.province"
                outlined
                background-color="white"
                class="text-sm"
                data-pptr="province"
                item-text="province"
                item-value="province"
                label="จังหวัด"
                ref="province"
                :class="{
                  'has-error': $v.form.province.$error,
                }"
                :items="provinceData"
                :error="$v.form.province.$error"
                :color="colorBnn['color-bnn']"
              ></v-autocomplete>
            </div>

            <div>
              <v-autocomplete
                v-model="form.district"
                ref="district"
                outlined
                background-color="white"
                class="text-sm"
                data-pptr="amphoe"
                item-text="amphoe"
                item-value="amphoe"
                label="เขต/อำเภอ"
                :color="colorBnn['color-bnn']"
                :error="$v.form.district.$error"
                :class="{
                  'has-error': $v.form.district.$error,
                }"
                :items="districtData"
              ></v-autocomplete>
            </div>

            <div>
              <v-autocomplete
                v-model="form.sub_district"
                ref="sub_district"
                background-color="white"
                class="text-sm"
                data-pptr="sub_district"
                item-text="district"
                item-value="district"
                placeholder="แขวง/ตำบล"
                outlined
                label="แขวง/ตำบล"
                :color="colorBnn['color-bnn']"
                :error="$v.form.sub_district.$error"
                :class="{
                  'has-error': $v.form.sub_district.$error,
                }"
                :items="subDistrictData"
              ></v-autocomplete>
            </div>

            <template v-if="samsungHandraiser">
              <div class="span-2">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <v-autocomplete
                      v-model="form.additional_description.brand"
                      ref="taxSubDistrict"
                      clearable
                      background-color="white"
                      class="text-sm"
                      data-pptr="brand"
                      item-text="brand"
                      item-value="brand"
                      placeholder="ยี่ห้อมือถือที่ใช้งานอยู่"
                      :color="colorBnn['color-bnn']"
                      :items="additionalDescriptionBrand"
                      @click:clear="
                        form.additional_description.model = ''
                      "
                      outlined
                      label="ยี่ห้อมือถือที่ใช้งานอยู่"
                      hide-details
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="py-0">
                    <v-autocomplete
                      outlined
                      v-model="form.additional_description.model"
                      :color="colorBnn['color-bnn']"
                      :items="additionalDescriptionModel"
                      :error="
                        $v.form.additional_description.model.$error
                      "
                      :error-messages="
                        $v.form.additional_description.model.$error
                          ? 'กรุณาเลือกรุ่นมือถือที่ใช้งานอยู่'
                          : ''
                      "
                      :class="{
                        'has-error':
                          $v.form.additional_description.model.$error,
                      }"
                      clearable
                      background-color="white"
                      class="text-sm"
                      data-pptr="model"
                      item-text="model"
                      item-value="model"
                      hide-details
                      label="รุ่นมือถือที่ใช้งานอยู่"
                      ref="taxSubDistrict"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>

              <div class="span-2">
                <v-autocomplete
                  outlined
                  v-model="form.additional_description.interest_item"
                  :color="colorBnn['color-bnn']"
                  :items="additionalDescriptionInterest"
                  clearable
                  background-color="white"
                  class="text-sm"
                  data-pptr="interest_item"
                  item-text="interest_item"
                  item-value="interest_item"
                  hide-details
                  label="ลูกค้าสนใจ promotion แลกซื้อหรือไม่ / หากสนใจเป็นสินค้าใด?"
                  ref="interest_item"
                ></v-autocomplete>
              </div>
            </template>
          </div>

          <div class="headline-m">
            ที่อยู่ใบกำกับภาษี
          </div>

          <div class="customer-form-container">
            <div class="span-2" v-if="customFieldOnCart">
              <h3 class="text-xl font-semibold">
                <v-checkbox
                  :input-value="form.need_true_phone"
                  :true-value="true"
                  :color="colorBnn['color-bnn']"
                  hide-details
                  label="ต้องการเปิดเบอร์ True รับส่วนลดสูงสุด 8,800 บาท"
                  background-color="#f5f5f7"
                  @click="checkNeedTruePhone"
                ></v-checkbox>
              </h3>
            </div>
            <div class="span-2">
              <h3 class="text-xl font-semibold">
                <v-checkbox
                  v-model="form.checkReceipt"
                  :true-value="true"
                  :color="colorBnn['color-bnn']"
                  hide-details
                  label="ต้องการรับใบกำกับภาษี / แก้ไขใบเสร็จ"
                  background-color="#f5f5f7"
                ></v-checkbox>
              </h3>
              <div class="warning-tax-invoice studio-7">
                <div class="warning-tax-invoice-title">
                  <span class="text-alert">สำคัญ!</span>
                  ใบกำกับภาษีของท่านจะถูกจัดส่งทางอีเมล
                </div>
                <div>
                  กรุณาตรวจสอบชื่อและที่อยู่ในการออกใบกำกับภาษีให้ถูกต้องทุกครั้ง
                  ทางบริษัทฯขอสงวนสิทธิ์ในการแก้ไขใบกำกับภาษี
                </div>
              </div>
            </div>

            <template v-if="form.checkReceipt">
              <div>
                <v-radio-group
                  v-model="form.taxInvoiceType"
                  inline
                  :error="$v.form.taxInvoiceType.$error"
                  ref="taxInvoiceType"
                  data-pptr="taxInvoiceType"
                >
                  <v-radio
                    :label="`ใบกำกับภาษีส่วนบุคคล`"
                    hide-details
                    value="PERSONAL"
                    :color="colorBnn['color-bnn']"
                  ></v-radio>
                </v-radio-group>
              </div>

              <div>
                <v-radio-group
                  v-model="form.taxInvoiceType"
                  inline
                  :error="$v.form.taxInvoiceType.$error"
                  ref="taxInvoiceType"
                  data-pptr="taxInvoiceType"
                >
                  <v-radio
                    hide-details
                    value="CORPORATION"
                    :label="`ใบกำกับภาษีนิติบุคคล`"
                    :color="colorBnn['color-bnn']"
                  ></v-radio>
                </v-radio-group>
              </div>

              <!-- Personal -->
              <template v-if="checkTaxPersonal">
                <div class="span-2">
                  <v-row
                    v-bind="{
                      'no-gutters': true,
                    }"
                  >
                    <v-col
                      cols="6"
                      sm="5"
                      class="input-compact-container"
                    >
                      <v-select
                        v-model="form.checkTaxPersonalType"
                        :items="[
                          {
                            text: 'เลขประจำตัวผู้เสียภาษี',
                            value: 'id_card',
                          },
                          { text: 'Passport', value: 'passport' },
                        ]"
                        :color="colorBnn['color-bnn']"
                        :width="150"
                        :max-width="150"
                        class="input-compact"
                        outlined
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="7"
                      class="input-compact-container"
                    >
                      <v-text-field
                        v-if="form.checkTaxPersonalType === 'id_card'"
                        outlined
                        :color="colorBnn['color-bnn']"
                        v-model="form.tax_id"
                        background-color="white"
                        data-pptr="tax_id"
                        class="input-customer text-sm input-compact"
                        :error="$v.form.tax_id.$error"
                        :error-messages="
                          handleErrorCustomerIdentity('tax_id')
                        "
                        :hide-details="!$v.form.tax_id.$error"
                        ref="tax_id"
                        maxlength="17"
                        @input="
                          autoFormatIdCard($event, 'tax_id'),
                            $v.form.tax_id.$reset()
                        "
                        inputmode="numeric"
                        oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                        placeholder="เลขประจำตัวผู้เสียภาษี"
                      />

                      <v-text-field
                        v-if="
                          form.checkTaxPersonalType === 'passport'
                        "
                        outlined
                        :color="colorBnn['color-bnn']"
                        v-model="form.tax_id"
                        background-color="white"
                        data-pptr="tax_id"
                        class="input-customer text-sm input-compact"
                        :error="$v.form.tax_id.$error"
                        :error-messages="
                          handleErrorCustomerIdentity('tax_id')
                        "
                        :hide-details="!$v.form.tax_id.$error"
                        ref="tax_id"
                        maxlength="12"
                        oninput="this.value=this.value.slice(0,12).replace(/[^\w]+/g, '')"
                        placeholder="Passport"
                      />
                    </v-col>
                  </v-row>
                </div>

                <div>
                  <v-text-field
                    v-model="form.taxFirstName"
                    background-color="white"
                    data-pptr="taxFirstName"
                    class="input-customer text-sm"
                    ref="taxFirstName"
                    maxlength="100"
                    type="text"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.taxFirstName.$error"
                    outlined
                    hide-details
                    label="ชื่อ"
                  />
                </div>
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    label="นามสกุล"
                    v-model="form.taxLastName"
                    background-color="white"
                    data-pptr="taxLastName"
                    class="input-customer text-sm"
                    maxlength="100"
                    ref="taxLastName"
                    type="text"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.taxLastName.$error"
                  />
                </div>
              </template>
              <!-- Company -->
              <template v-if="checkTaxJuristic">
                <div>
                  <v-text-field
                    v-model="form.tax_id"
                    outlined
                    :color="colorBnn['color-bnn']"
                    background-color="white"
                    data-pptr="tax_id"
                    class="input-customer text-sm input-compact"
                    :error="$v.form.tax_id.$error"
                    :error-messages="
                      handleErrorCustomerIdentity('tax_id')
                    "
                    :hide-details="!$v.form.tax_id.$error"
                    ref="tax_id"
                    maxlength="17"
                    @input="
                      autoFormatIdCard($event, 'tax_id'),
                        $v.form.tax_id.$reset()
                    "
                    oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                    inputmode="numeric"
                    placeholder="เลขประจำตัวผู้เสียภาษี"
                    label="เลขประจำตัวผู้เสียภาษี"
                  />
                </div>

                <div></div>
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    label="ชื่อบริษัท"
                    v-model="form.taxCompanyName"
                    background-color="white"
                    data-pptr="taxCompanyName"
                    class="input-customer text-sm"
                    maxlength="100"
                    type="text"
                    ref="taxCompanyName"
                    placeholder="ชื่อบริษัท"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.taxCompanyName.$error"
                  />
                </div>
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    label="รหัสสาขา"
                    v-model="form.taxCompanyCode"
                    background-color="white"
                    data-pptr="taxCompanyCode"
                    class="input-customer text-sm"
                    maxlength="100"
                    type="text"
                    ref="taxCompanyCode"
                    placeholder="รหัสสาขา"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.taxCompanyCode.$error"
                  />
                </div>
              </template>

              <div class="span-2">
                <h3 class="text-xl">
                  <v-checkbox
                    class="mt-0"
                    v-model="form.sameAsDeliveryAddress"
                    hide-details
                    :label="`ที่อยู่เดียวกับที่อยู่จัดส่ง`"
                    :true-value="true"
                    :color="colorBnn['color-bnn']"
                    background-color="#f5f5f7"
                  ></v-checkbox>
                </h3>
              </div>

              <template v-if="!form.sameAsDeliveryAddress">
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    label="บ้าน/อาคาร/เลขที่"
                    v-model="form.taxAddress1"
                    background-color="white"
                    data-pptr="taxAddress1"
                    class="input-customer text-sm"
                    maxlength="100"
                    type="text"
                    ref="taxAddress1"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.taxAddress1.$error"
                  />
                </div>
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    label="หมู่บ้าน/อาคาร/สถานที่"
                    v-model="form.taxAddress2"
                    background-color="white"
                    data-pptr="taxAddress2"
                    class="input-customer text-sm"
                    maxlength="100"
                    type="text"
                    ref="taxAddress2"
                    :color="colorBnn['color-bnn']"
                  />
                </div>
                <div class="span-2">
                  <v-text-field
                    outlined
                    hide-details
                    label="ที่อยู่/ถนน/ซอย"
                    v-model="form.taxAddress3"
                    background-color="white"
                    data-pptr="taxAddress3"
                    class="input-customer text-sm"
                    maxlength="100"
                    type="text"
                    ref="taxAddress3"
                    placeholder="ที่อยู่/ถนน/ซอย"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.taxAddress3.$error"
                  />
                </div>
                <div>
                  <v-autocomplete
                    outlined
                    hide-details
                    label="จังหวัด"
                    v-model="form.taxProvince"
                    background-color="white"
                    data-pptr="taxProvince"
                    item-text="province"
                    item-value="province"
                    placeholder="จังหวัด"
                    ref="taxProvince"
                    :items="provinceData"
                    :error="$v.form.taxProvince.$error"
                    :color="colorBnn['color-bnn']"
                    :class="[
                      'text-sm input-transparent',
                      {
                        'has-error': $v.form.taxProvince.$error,
                      },
                    ]"
                  ></v-autocomplete>
                </div>
                <div>
                  <v-autocomplete
                    outlined
                    hide-details
                    label="เขต/อำเภอ"
                    v-model="form.taxDistrict"
                    background-color="white"
                    data-pptr="taxDistrict"
                    item-text="amphoe"
                    item-value="amphoe"
                    placeholder="เขต/อำเภอ"
                    ref="taxDistrict"
                    :color="colorBnn['color-bnn']"
                    :class="[
                      'text-sm',
                      {
                        'has-error': $v.form.taxDistrict.$error,
                      },
                    ]"
                    :error="$v.form.taxDistrict.$error"
                    :items="taxDistrictData"
                  ></v-autocomplete>
                </div>
                <div>
                  <v-autocomplete
                    outlined
                    hide-details
                    label="แขวง/ตำบล"
                    v-model="form.taxSubDistrict"
                    background-color="white"
                    data-pptr="taxSubDistrict"
                    item-text="district"
                    item-value="district"
                    placeholder="แขวง/ตำบล"
                    ref="taxSubDistrict"
                    :color="colorBnn['color-bnn']"
                    :class="[
                      'text-sm',
                      {
                        'has-error': $v.form.taxSubDistrict.$error,
                      },
                    ]"
                    :error="$v.form.taxSubDistrict.$error"
                    :items="taxSubDistrictData"
                  ></v-autocomplete>
                </div>
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    label="รหัสไปรษณีย์"
                    v-model="form.taxPostcode"
                    background-color="white"
                    data-pptr="taxPostcode"
                    class="input-customer text-sm"
                    type="text"
                    ref="taxPostcode"
                    maxlength="5"
                    placeholder="รหัสไปรษณีย์"
                    inputmode="numeric"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.taxPostcode.$error"
                    @keypress="isNumber($event)"
                  />
                </div>
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    label="เบอร์โทรศัพท์"
                    v-model="form.taxPhoneNumber"
                    background-color="white"
                    data-pptr="taxPhoneNumber"
                    class="input-customer text-sm"
                    maxlength="10"
                    type="tel"
                    ref="taxPhoneNumber"
                    placeholder="เบอร์โทรศัพท์"
                    :color="colorBnn['color-bnn']"
                    :error="$v.form.taxPhoneNumber.$error"
                    @keypress="isNumber($event)"
                  />
                </div>
              </template>
            </template>
          </div>
        </div>
      </v-container>
    </section>

    <section class="action-container">
      <v-container>
        <v-row
          v-bind="{
            justify: 'center',
            'justify-sm': 'end',
            align: 'center',
          }"
        >
          <v-col cols="auto">
            <v-btn
              block
              depressed
              large
              text
              color="#6E6E73"
              @click="openResetModal"
            >
              ล้างข้อมูล
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              block
              depressed
              large
              class="font-bold"
              :dark="!isBnn"
              :color="colorBnn['color-bnn']"
              @click="submitForm"
            >
              ยืนยันข้อมูลของท่าน
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-dialog v-model="dialog" persistent max-width="340">
      <v-card>
        <div class="header-modal-container">
          <div class="header-modal">
            <span class="header-modal-title">
              <div class="d-flex">
                <span class="font-bold text-black">
                  ล้างข้อมูล
                </span>
              </div>
            </span>
          </div>
        </div>

        <div class="body-modal">
          <v-row v-bind="{ justify: 'end', 'no-gutters': true }">
            <v-col class="text-center">
              คุณแน่ใจหรือไม่ว่าต้องการล้างข้อมูลทั้งหมด
            </v-col>

            <v-col cols="12" class="mt-8">
              <v-btn
                block
                depressed
                large
                color="#FF4252"
                class="text-white font-bold"
                @click="reset"
              >
                ยืนยันล้างข้อมูล
              </v-btn>
            </v-col>

            <v-col cols="12" class="mt-4">
              <v-btn
                block
                depressed
                link
                large
                @click="closeResetModal"
              >
                ยกเลิก
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import colorBnn from '@/config/color'
import isBnn from '@/config/isBnn'
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  maxLength,
  numeric,
  requiredIf,
} from 'vuelidate/lib/validators'
import branchTypes from '@/config/branchTypes'
import additionalDescription from '@/config/additionalDescription'
import {
  validateIdCard,
  validateStartWithZero,
  validatePassport,
} from '@/utils/helpers'

export default {
  name: 'CustomerInformationForm',

  mixins: [validationMixin],

  components: {
    // Delivery,
    SevenClubMember: () =>
      import('@/components/bnn/SevenClubMember.vue'),
    Load: () => import('@/views/Load.vue'),
  },

  computed: {
    ...mapState({
      campaign: state => state.campaign.current,
      isMemberLoading: state => state.user.member.isLoading,
      member: state => state.user.member.data,
      isError: state => state.user.member.isError,
    }),
    customFieldOnCart() {
      //   return this.campaign.data?.custom_field_on_cart
      return false
    },
    samsungHandraiser() {
      return this.campaign.data?.handraiser === 'samsung'
    },
    provinceData() {
      return this.$store.getters['thailandStore/getProvince']
    },
    districtData() {
      return this.$store.getters['thailandStore/getDistrict'](
        this.form.province,
      )
    },
    taxDistrictData() {
      return this.$store.getters['thailandStore/getDistrict'](
        this.form.taxProvince,
      )
    },
    zipcodeData() {
      return this.$store.getters['thailandStore/getZipcode'](
        this.form.district,
        this.form.sub_district,
      )
    },
    taxZipcodeData() {
      return this.$store.getters['thailandStore/getZipcode'](
        this.form.taxDistrict,
        this.form.taxSubDistrict,
      )
    },
    taxSubDistrictData() {
      return this.$store.getters['thailandStore/getSubDistrict'](
        this.form.taxProvince,
        this.form.taxDistrict,
      )
    },
    subDistrictData() {
      return this.$store.getters['thailandStore/getSubDistrict'](
        this.form.province,
        this.form.district,
      )
    },
    checkTaxPersonal() {
      return this.form.taxInvoiceType === 'PERSONAL'
    },
    checkTaxJuristic() {
      return this.form.taxInvoiceType === 'CORPORATION'
    },
    additionalDescriptionBrand() {
      let brands = additionalDescription.model.map(item => item.brand)
      return brands.filter(
        (brand, index) => brands.indexOf(brand) === index,
      )
    },
    additionalDescriptionModel() {
      return additionalDescription.model
        .filter(
          item =>
            item.brand === this.form.additional_description.brand,
        )
        .map(item => item.model)
    },
    additionalDescriptionInterest() {
      return additionalDescription.interestItem
    },
    decryptedData() {
      return this.$store.getters['user/getData']
    },
    isMember() {
      let localMember = localStorage.getItem('isMember')
      return !!localMember || !!this.member
    },
    isShowForm() {
      return (
        (this.isHavePurchased && this.isMember) ||
        !this.isHavePurchased
      )
    },
  },

  data() {
    return {
      colorBnn,
      isBnn,
      dialog: false,

      branchTypes,

      initForm: {
        first_name: '',
        last_name: '',
        customer_identity: '',
        phone: '',
        email: '',
        address: '',
        sub_district: '',
        district: '',
        province: '',
        postcode: '',
        need_true_phone: false,
        checkReceipt: false,
        checkTaxPersonal: false,
        checkTaxJuristic: false,
        taxInvoiceType: 'PERSONAL', //  'PERSONAL', 'CORPORATION'
        tax_id: '',
        taxFirstName: '',
        taxLastName: '',
        taxCompanyName: '',
        taxCompanyCode: '',
        taxAddress1: '',
        taxAddress2: '',
        taxAddress3: '',
        taxProvince: '',
        taxDistrict: '',
        taxSubDistrict: '',
        taxPostcode: '',
        taxPhoneNumber: '',

        // other
        for_work: false,

        // additional_description
        additional_description: {
          brand: '',
          model: '',
          interest_item: '',
        },

        // Don't send to API
        sameAsDeliveryAddress: true, // tax address same as delivery address
        customerIdentityType: 'id_card', // id_card, passport
        checkTaxPersonalType: 'id_card', // id_card, passport
      },
      form: {
        first_name: '',
        last_name: '',
        customer_identity: '',
        phone: '',
        email: '',
        address: '',
        sub_district: '',
        district: '',
        province: '',
        postcode: '',
        need_true_phone: false,
        checkReceipt: false,
        checkTaxPersonal: false,
        checkTaxJuristic: false,
        taxInvoiceType: 'PERSONAL', //  'PERSONAL', 'CORPORATION'
        tax_id: '',
        taxFirstName: '',
        taxLastName: '',
        taxCompanyName: '',
        taxCompanyCode: '',
        taxAddress1: '',
        taxAddress2: '',
        taxAddress3: '',
        taxProvince: '',
        taxDistrict: '',
        taxSubDistrict: '',
        taxPostcode: '',
        taxPhoneNumber: '',

        // other
        for_work: false,

        // additional_description
        additional_description: {
          brand: '',
          model: '',
          interest_item: '',
        },

        // Don't send to API
        sameAsDeliveryAddress: false, // tax address same as delivery address
        customerIdentityType: 'id_card', // id_card, passport
        checkTaxPersonalType: 'id_card', // id_card, passport
      },

      errorIdCardFormat: false,
      errorTaxIdFormat: false,
      isDecryptedLoading: false,
      //   customerIdentityType: 'id_card', // id_card, passport
      //   checkTaxPersonalType: 'id_card', // id_card, passport

      isHavePurchased:
        localStorage.getItem('registered') &&
        localStorage.getItem('isMember')
          ? true
          : localStorage.getItem('registered')
          ? false
          : true,
      havePurchasedOptions: [
        { text: 'เคยแล้ว', value: true },
        { text: 'ไม่เคยเลย', value: false },
      ],
    }
  },

  validations: {
    form: {
      // Hide for now
      //   payment_type_id: { required },
      //   delivery: { required },
      //   for_work: { required },
      //   branch_province: {
      //     required: requiredIf(function() {
      //       return this.isStore
      //     }),
      //   },
      //   branch_time_id: {
      //     required: requiredIf(function() {
      //       return this.isStore
      //     }),
      //   },
      email: { required, email, maxLength: maxLength(40) },
      phone: { required, numeric, maxLength: maxLength(20) },
      first_name: { required, maxLength: maxLength(40) },
      last_name: { required, maxLength: maxLength(40) },
      customer_identity: {
        required,
        valid: function(value) {
          if (this.form.customerIdentityType === 'id_card') {
            return validateIdCard(value)
          } else if (this.form.customerIdentityType === 'passport') {
            return validatePassport(value)
          }
          return true
        },
      },
      address: { required, maxLength: maxLength(100) },
      postcode: { required, maxLength: maxLength(5) },
      province: {
        required,
        maxLength: maxLength(100),
        valid: function(value) {
          return this.provinceData.some(
            item => item.province === value,
          )
        },
      },
      district: {
        required,
        maxLength: maxLength(100),
        valid: function(value) {
          return this.districtData.some(item => item.amphoe === value)
        },
      },
      sub_district: {
        required,
        maxLength: maxLength(100),
        valid: function(value) {
          return this.subDistrictData.some(
            item => item.district === value,
          )
        },
      },

      taxInvoiceType: {
        required: requiredIf(function() {
          return this.form.checkReceipt
        }),
      },
      tax_id: {
        required: requiredIf(function() {
          return this.form.checkReceipt
        }),
        valid: function(value) {
          if (this.form.checkReceipt) {
            if (this.checkTaxPersonal) {
              if (this.form.checkTaxPersonalType === 'passport') {
                return validatePassport(value)
              }

              return validateIdCard(value)
            } else if (this.checkTaxJuristic) {
              return validateStartWithZero(value)
            }
          }
          return true
        },
      },
      taxFirstName: {
        required: requiredIf(function() {
          return this.form.checkReceipt && this.checkTaxPersonal
        }),
      },
      taxLastName: {
        required: requiredIf(function() {
          return this.form.checkReceipt && this.checkTaxPersonal
        }),
      },
      taxCompanyName: {
        required: requiredIf(function() {
          return this.form.checkReceipt && this.checkTaxJuristic
        }),
      },
      taxCompanyCode: {
        required: requiredIf(function() {
          //   return this.form.checkReceipt && this.checkTaxJuristic
          return false
        }),
      },
      taxAddress1: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt && !this.form.sameAsDeliveryAddress
          )
        }),
      },
      taxAddress3: {
        required: requiredIf(function() {
          //   return (
          //     this.form.checkReceipt && !this.form.sameAsDeliveryAddress
          //   )
          return false
        }),
      },
      taxProvince: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt && !this.form.sameAsDeliveryAddress
          )
        }),
        valid: function(value) {
          return this.form.checkReceipt &&
            !this.form.sameAsDeliveryAddress
            ? this.provinceData.some(item => item.province === value)
            : true
        },
      },
      taxDistrict: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt && !this.form.sameAsDeliveryAddress
          )
        }),
        valid: function(value) {
          return this.form.checkReceipt &&
            !this.form.sameAsDeliveryAddress
            ? this.taxDistrictData.some(item => item.amphoe === value)
            : true
        },
      },
      taxSubDistrict: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt && !this.form.sameAsDeliveryAddress
          )
        }),
        valid: function(value) {
          return this.form.checkReceipt &&
            !this.form.sameAsDeliveryAddress
            ? this.taxSubDistrictData.some(
                item => item.district === value,
              )
            : true
        },
      },
      taxPostcode: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt && !this.form.sameAsDeliveryAddress
          )
        }),
      },
      taxPhoneNumber: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt && !this.form.sameAsDeliveryAddress
          )
        }),
      },
      additional_description: {
        model: {
          required: requiredIf(function() {
            return this.form.additional_description.brand
          }),
        },
      },
    },
  },

  watch: {
    'form.province'() {
      if (this.form.postcode == '') {
        this.form.sub_district = ''
        this.form.district = ''
        this.form.postcode = ''
        // localStorage.setItem('sub_district', '')
        // localStorage.setItem('district', '')
        // localStorage.setItem('postcode', '')
      }
    },
    'form.district'() {
      if (this.form.postcode == '') {
        this.form.sub_district = ''
        this.form.postcode = ''
        // localStorage.setItem('sub_district', '')
        // localStorage.setItem('postcode', '')
      }
    },
    'form.sub_district'(val, oldVal) {
      if (val != oldVal && (oldVal || this.form.postcode == '')) {
        this.form.postcode = this.zipcodeData
      }
      //   localStorage.setItem('postcode', this.zipcodeData)
    },
    'form.taxProvince'(val, oldVal) {
      if (
        val != oldVal &&
        oldVal &&
        !this.form.sameAsDeliveryAddress
      ) {
        this.form.taxDistrict = ''
        this.form.taxSubDistrict = ''
        this.form.taxPostcode = ''
      }
      //   if (!this.form.sameAsDeliveryAddress) {
      //     this.form.taxDistrict = ''
      //     this.form.taxSubDistrict = ''
      //     this.form.taxPostcode = ''
      //     // localStorage.setItem('taxDistrict', '')
      //     // localStorage.setItem('taxSubDistrict', '')
      //     // localStorage.setItem('taxPostcode', '')
      //   }
    },
    'form.taxDistrict'(val, oldVal) {
      if (
        val != oldVal &&
        oldVal &&
        !this.form.sameAsDeliveryAddress
      ) {
        this.form.taxSubDistrict = ''
        this.form.taxPostcode = ''
        // localStorage.setItem('taxSubDistrict', '')
        // localStorage.setItem('taxPostcode', '')
      }
    },
    'form.taxSubDistrict'() {
      if (!this.form.sameAsDeliveryAddress) {
        this.form.taxPostcode = this.taxZipcodeData
        // localStorage.setItem('taxPostcode', this.taxZipcodeData)
      }
    },
    // 'form.checkReceipt'() {
    //     if (this.form.checkReceipt) {
    //       if (this.form.taxInvoiceType == 'PERSONAL') {
    //         this.form.tax_id =
    //           this.form.tax_id || this.form.customer_identity || ''
    //         this.form.taxFirstName = this.form.first_name || ''
    //         this.form.taxLastName = this.form.last_name || ''
    //       }
    //     } else {
    //       this.form.tax_id = ''
    //       this.form.taxFirstName = ''
    //       this.form.taxLastName = ''
    //     }
    // },
    checkTaxPersonal(val) {
      if (val) {
        if (this.decryptedData.taxInvoiceType === 'PERSONAL') {
          this.form.tax_id = this.decryptedData.checkReceipt
            ? this.decryptedData.tax_id
            : this.form.customer_identity
          this.form.taxFirstName =
            this.decryptedData.taxFirstName || this.form.first_name
          this.form.taxLastName =
            this.decryptedData.taxLastName || this.form.last_name

          if (!this.decryptedData.sameAsDeliveryAddress) {
            this.form.sameAsDeliveryAddress = this.decryptedData.sameAsDeliveryAddress
            this.form.taxAddress1 =
              this.form.taxAddress1 || this.decryptedData.taxAddress1
            this.form.taxAddress2 =
              this.form.taxAddress2 || this.decryptedData.taxAddress2
            this.form.taxAddress3 =
              this.form.taxAddress3 || this.decryptedData.taxAddress3
            this.form.taxSubDistrict =
              this.form.taxSubDistrict ||
              this.decryptedData.taxSubDistrict
            this.form.taxDistrict =
              this.form.taxDistrict || this.decryptedData.taxDistrict
            this.form.taxProvince =
              this.form.taxProvince || this.decryptedData.taxProvince
            this.form.taxPostcode =
              this.form.taxPostcode || this.decryptedData.taxPostcode
            this.form.taxPhoneNumber =
              this.form.taxPhoneNumber ||
              this.decryptedData.taxPhoneNumber
          } else {
            this.form.sameAsDeliveryAddress = true
            this.form.taxAddress1 = ''
            this.form.taxAddress2 = ''
            this.form.taxAddress3 = ''
            this.form.taxSubDistrict = ''
            this.form.taxDistrict = ''
            this.form.taxProvince = ''
            this.form.taxPostcode = ''
            this.form.taxPhoneNumber = ''
          }
        } else {
          this.form.tax_id = this.form.customer_identity
          this.form.taxFirstName =
            this.form.taxFirstName || this.form.first_name
          this.form.taxLastName =
            this.form.taxLastName || this.form.last_name
          this.form.sameAsDeliveryAddress = true
          this.form.taxAddress1 = ''
          this.form.taxAddress2 = ''
          this.form.taxAddress3 = ''
          this.form.taxSubDistrict = ''
          this.form.taxDistrict = ''
          this.form.taxProvince = ''
          this.form.taxPostcode = ''
          this.form.taxPhoneNumber = ''
        }
      }
    },
    checkTaxJuristic(val) {
      if (val) {
        this.form.sameAsDeliveryAddress = false

        if (this.decryptedData.taxInvoiceType === 'CORPORATION') {
          this.form.tax_id = this.decryptedData.tax_id || ''
          this.form.taxCompanyName =
            this.form.taxCompanyName ||
            this.decryptedData.taxCompanyName
          this.form.taxCompanyCode =
            this.form.taxCompanyCode ||
            this.decryptedData.taxCompanyCode
        } else {
          if (!this.decryptedData.taxCompanyName) {
            this.form.tax_id = ''
            this.form.taxCompanyName = ''
            this.form.taxCompanyCode = ''
          } else {
            this.form.tax_id = this.decryptedData.tax_id || ''
            this.form.taxCompanyName =
              this.decryptedData.taxCompanyName || ''
            this.form.taxCompanyCode =
              this.decryptedData.taxCompanyCode || ''
          }
        }

        this.form.taxAddress1 =
          this.form.taxAddress1 || this.decryptedData.taxAddress1
        this.form.taxAddress2 =
          this.form.taxAddress2 || this.decryptedData.taxAddress2
        this.form.taxAddress3 =
          this.form.taxAddress3 || this.decryptedData.taxAddress3
        this.form.taxProvince =
          this.form.taxProvince || this.decryptedData.taxProvince
        this.form.taxDistrict =
          this.form.taxDistrict || this.decryptedData.taxDistrict
        this.form.taxSubDistrict =
          this.form.taxSubDistrict ||
          this.decryptedData.taxSubDistrict
        this.form.taxPostcode =
          this.form.taxPostcode || this.decryptedData.taxPostcode
        this.form.taxPhoneNumber =
          this.form.taxPhoneNumber ||
          this.decryptedData.taxPhoneNumber
      }
    },
  },

  async mounted() {
    if (this.campaign?.data?.redirect_url) {
      window.location.href = this.campaign?.data?.redirect_url
    }

    this.prepareDataForm()

    if (this.isMember) {
      this.isHavePurchased = true
    }
  },

  methods: {
    async prepareDataForm() {
      this.isDecryptedLoading = true

      this.$store.dispatch('user/fetchData')

      this.form = { ...this.form, ...this.decryptedData }

      this.isDecryptedLoading = false

      this.form.sameAsDeliveryAddress = this.decryptedData.sameAsDeliveryAddress
      this.form.customerIdentityType =
        this.decryptedData.customerIdentityType ?? 'id_card'
      this.form.checkTaxPersonalType =
        this.decryptedData.checkTaxPersonalType ?? 'id_card'

      // Auto replace format customer_identity
      if (this.form.customer_identity) {
        this.form.customer_identity = this.form.customer_identity.replace(
          /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/,
          '$1-$2-$3-$4-$5',
        )
      }

      // Set default value
      if (!this.decryptedData.checkReceipt) {
        // data checkReceipt is false
        // Default value for tax personal
        this.form.tax_id = this.form.customer_identity
        this.form.taxFirstName = this.form.first_name
        this.form.taxLastName = this.form.last_name
        this.form.sameAsDeliveryAddress = true
      } else {
        this.form.tax_id =
          this.decryptedData.tax_id || this.form.customer_identity
        this.form.taxFirstName =
          this.decryptedData.taxFirstName || this.form.first_name
        this.form.taxLastName =
          this.decryptedData.taxLastName || this.form.last_name
      }

      // ------
    },
    persist(key, value) {
      if (value) {
        localStorage.setItem(key, value)
      } else {
        localStorage.removeItem(key)
      }
    },
    checkSameAsDeliveryAddress() {
      if (this.form.sameAsDeliveryAddress) {
        this.setTaxFullName()
        return false
      } else {
        return true
      }
    },
    setTaxFullName() {
      if (this.checkTaxPersonal) {
        this.form.taxFirstName = this.form.first_name
        this.form.taxLastName = this.form.last_name
      } else {
        this.form.taxFirstName = ''
        this.form.taxLastName = ''
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    onBlurAutoComplete() {
      if (this.form.postcode) {
        let filteredLocations = this.$store.getters[
          'thailandStore/getAll'
        ].filter(location => location.zipcode == this.form.postcode)

        if (filteredLocations.length > 0) {
          this.form.province = filteredLocations[0].province
          this.form.district = filteredLocations[0].amphoe
          this.form.sub_district = filteredLocations[0].district
        }
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    openResetModal() {
      this.dialog = true
    },
    closeResetModal() {
      this.dialog = false
    },
    reset() {
      this.$v.$reset()

      this.form = this.initForm

      localStorage.removeItem('registered')
      localStorage.removeItem('isMember')

      this.$store.dispatch('user/resetMember')
      this.closeResetModal()
      this.goToCountDownPage()
    },
    submitForm() {
      this.$v.form.$touch()

      if (this.$v.form.$invalid) {
        for (let input in this.$v.form) {
          if (!input.includes('$') && this.$v.form[input].$error) {
            const element = document.querySelector(
              `[data-pptr='${input}']`,
            )
            if (element)
              element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })

            if (this.$refs[input]?.focus) {
              setTimeout(() => this.$refs[input]?.focus(), 300)
            }
            break
          }
        }
        return
      }

      if (this.$v.form.$error) {
        return
      }

      this.$store.dispatch('user/setData', this.form)

      this.goToCountDownPage()
    },
    goToCountDownPage() {
      this.$router.push({
        name: 'CountDown',
        params: { slug: this.$route.params?.slug },
      })
    },
    autoFormatIdCard(value, feild) {
      // Remove all non-digit characters
      let cleaned = value.slice(0, 17).replace(/[^\d]+/g, '')

      // Format the cleaned string
      let match = cleaned.match(
        /^(\d{0,1})(\d{0,4})(\d{0,5})(\d{0,2})(\d{0,1})/,
      )

      if (match) {
        cleaned = `${match[1] || ''}${
          match[2] ? '-' + match[2] : ''
        }${match[3] ? '-' + match[3] : ''}${
          match[4] ? '-' + match[4] : ''
        }${match[5] ? '-' + match[5] : ''}`
      }

      //   Update the input value
      this.form[feild] = cleaned
    },

    handleErrorCustomerIdentity(input) {
      if (this.$v.form[input].$error) {
        if (!this.$v.form[input].required) {
          return ''
        } else if (this.$v.form[input].$invalid) {
          if (
            (input === 'customer_identity' &&
              this.form.customerIdentityType === 'passport') ||
            (input === 'tax_id' &&
              this.form.checkTaxPersonalType === 'passport')
          ) {
            return 'Please enter a valid passport number.'
          }
          return input === 'tax_id'
            ? 'กรุณากรอกเลขประจำตัวผู้เสียภาษีให้ถูกต้อง'
            : 'กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง'
        }
      }
      return ''
    },

    handleVerifySevenClubMember() {
      this.isHavePurchased = true
      this.prepareDataForm()
    },

    handleErrorSevenClubMember() {
      this.isHavePurchased = false
      this.autoFocusField('email')
    },

    autoFocusField(field) {
      var element = document.querySelector(`[data-pptr='${field}']`)

      if (!element) {
        return
      }

      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })

      if (this?.$refs[field]?.focus) {
        setTimeout(() => this?.$refs[field]?.focus(), 300)
      }
    },
  },

  beforeDestroy() {
    this.$store.dispatch('user/resetMember')
  },
}
</script>

<style lang="stylus" scoped>

.customer-form-section
    width 100%
    background-color #F9F9F9
.form-customer-layout
    width 100%
    @media medium
      width 100%
      padding 0
    @media small
      width 100%
      padding 0
.v-text-field.v-text-field--enclosed .v-text-field__details
  margin-bottom 0 !important
.v-messages.theme--light
  min-height 0px !important
.v-input--selection-controls
  margin-top 0px !important
.input-transparent
  background: transparent !important;
  border-color: transparent !important;
.branch-time
  display flex
  justify-content center
  align-items center
  margin-left -12px
  margin-right -12px
  .branch
    width 50%
    padding: 12px
    @media screen and (max-width: 960px)
      width 100%
  .time
     width 50%
     padding: 12px
     @media screen and (max-width: 960px)
       width 100%
   @media screen and (max-width: 960px)
    flex-direction column

::v-deep .v-input__slot {
  background: transparent !important;
  border-color: transparent !important;
}

.scb-easy-modal
  background: transparent linear-gradient(180deg, #A700FF 0%, #5500B9 100%) 0% 0% no-repeat padding-box;
.scb-card-qrcode
  background white
  border-radius: 8px;
  border: 1px solid #00000000;
  padding: 1.5rem
.button-back-scb
  border-color: white !important

.is-desktop
  display: block
  @media tablet
    display: none
.note-form-address
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  margin-top: -32px

  font-size: 0.875rem

  > div {
	text-align: center
  }

  @media tablet
    font-size: 0.875rem

  @media small
    font-size: 11px



.warning-tax-invoice
  width: 100%
  padding: 8px
  border: 1px solid #ccc
  border-radius: 8px
  font-size: 14px
  margin-top: 8px
  &.studio-7
    background: #f2f2f2;
    border: none

.warning-tax-invoice-title
  font-size: 15px
  font-weight: bold

.text-alert
  color: $color-red

.text-black
  color: $color-bnn-gray-medium

.action-container
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  z-index: 8
  background-color: $color-dapp-gray-4;
  box-shadow: inset 0 .5px #7d7d7d
  margin-top: 16px

.header-modal-container
  padding: 16px 16px 0

.header-modal
  display: flex
  justify-content: center
  width: 100%

.body-modal
  padding: 16px

.header-modal-title
  font-size: 24px
  font-weight: bold
  font-family: $font-family-base !important
  align-self: center


.header-modal-close
  cursor: pointer
  color: $color-bnn-gray-light
  align-self: center

.customer-form-container {
    display: grid
    grid-template-columns: repeat(1, 1fr)
    gap 21px 14px

    .span-2 {
        grid-column: span 1

        @media screen and (min-width: 768px){
            grid-column: span 2
        }
    }

    @media screen and (min-width: 768px){
        grid-template-columns: repeat(2, 1fr)
    }
}

.customer-container {
    display: flex
    flex-direction: column
    gap: 24px
    margin-bottom: 24px

    &:last-child {
        margin-bottom: 120px
    }
}

::v-deep {
    .input-compact-container {
        &:first-child {
            .input-compact {
                border-radius: 5px 0 0 5px !important;

                &:not(.v-input--is-focused) {
                    fieldset {
                        border-right: none !important;
                    }
                }
            }


        }
        &:last-child {
            .input-compact {
                border-radius: 0 5px 5px 0 !important;
            }
        }
    }

    .no-border-shadow .v-input__control {
        border: none !important;
        box-shadow: none !important;
    }
}
.seven-club-member-container {
    width: 100%
    display: flex
    justify-content: center
}

.status-scm {
    &-success {
        color: #008900
        text-align: center
    }
    &-warning {
        color: #BF4800
        text-align: center
    }
}

.content-section {
    background-color: white
    padding: 32px 0

    @media screen and (min-width: 960px) {
        padding: 64px 0
    }

    &:first-child {
        margin-bottom: 8px
    }
}
</style>
